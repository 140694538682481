import React from "react";
import styles from "./Summary.module.css";
import {downloadFile} from "../../fileSlice";
import {useAppDispatch} from "../../../app/hooks";

export const Summary: React.FC = () => {

    const dispatch = useAppDispatch();

    const handleClick = () => {
        dispatch(downloadFile());
    }

    return <div className={styles.summaryWrapper}>
        <span className={styles.title}>Download summary</span>
        <span className={styles.subTitle}>The report has been successfully analyzed and a summary with the results is now available for download.</span>
        <div className={styles.uploadButton}
             onClick={handleClick}>
            Download summary
        </div>
    </div>
}
