import React, {ChangeEvent, useEffect, useRef} from "react";
import styles from "./UploadComponent.module.css";
import {FileDropzone} from "../FileDropzone/FileDropzone";
import {useAppSelector} from "../../../app/hooks";
import {filesSelectedSelector, filesSelector, RequestStatus, requestStatusSelector} from "../../fileSlice";
import {SelectedFiles} from "../SelectedFiles/SelectedFiles";
import {Keywords} from "../Keywords/Keywords";
import {Summary} from "../Summary/Summary";

export const uploadFileId = 'uploadFile';

interface UploadComponentProps {
    handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export const UploadComponent: React.FC<UploadComponentProps> = ({handleFileChange}) => {

    const filesSelected = useAppSelector(filesSelectedSelector);
    const files = useAppSelector(filesSelector);
    const requestStatus = useAppSelector(requestStatusSelector);
    const bottomElementRef = useRef<null | HTMLDivElement>(null);

    const onUploadButtonClick = () => {
        const inputElement = window.document.getElementById(uploadFileId);
        inputElement?.click();
    }

    useEffect(() => {
        if (bottomElementRef) {
            bottomElementRef.current?.scrollIntoView({behavior: 'smooth'});
        }
    }, [requestStatus, files])

    return <div className={styles.uploadComponentWrapper}>
        <span className={styles.title}>Upload PDF</span>
        <span className={styles.subTitle}>Select multiple PDF documents containing the report for analysis</span>
        {!filesSelected && <FileDropzone
            onUploadButtonClick={onUploadButtonClick}
            handleFileChange={handleFileChange}
        />}
        {filesSelected && <>
            <SelectedFiles onUploadButtonClick={onUploadButtonClick}/>
            <Keywords/>
            {requestStatus == RequestStatus.FULFILLED && <Summary/>}
        </>}
        <div ref={bottomElementRef}/>
    </div>
}
