import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../app/store';

export const BASE_URL = window.location.hostname === 'localhost' ? 'http://localhost:8090' : 'https://gt.flipside-staging.com'

export enum RequestStatus {
    PENDING,
    FULFILLED,
    NONE
}

export interface FileState {
    files?: File[];
    filePath: string;
    keywords: string;
    keywordsProcessed: string;
    requestStatus: RequestStatus;
    downloadRequestStatus: RequestStatus;
}

const initialState: FileState = {
    filePath: '',
    keywords: '',
    keywordsProcessed: 'output',
    requestStatus: RequestStatus.NONE,
    downloadRequestStatus: RequestStatus.NONE
};

export const checkFiles = createAsyncThunk<void, undefined, { state: RootState }>(
    'files/checkFiles',
    async (_payload, {getState, dispatch}) => {
        const state = getState();
        let files = state.files.files;
        if (files && files.length) {
            dispatch(updateRequestStatus(RequestStatus.PENDING));
            const formData = new FormData()
            formData.set("file0", files[0] || new File([], 'placeholder'))
            formData.set("file1", files[1] || new File([], 'placeholder'))
            formData.set("file2", files[2] || new File([], 'placeholder'))
            formData.set("file3", files[3] || new File([], 'placeholder'))
            formData.set("file4", files[4] || new File([], 'placeholder'))
            formData.set("file5", files[5] || new File([], 'placeholder'))
            const request = new XMLHttpRequest();
            request.withCredentials = true;
            files.forEach((file, index) => {
                formData.set(`file${index}`, file);
            });
            request.onreadystatechange = () => {
                if (request.readyState === 4) {
                    if (request.status == 200 || request.status == 201) {
                        const processingFiles = JSON.parse(request.responseText)
                        if (processingFiles.length == 0) {
                            dispatch(processFiles())
                        } else {
                            dispatch(updateRequestStatus(RequestStatus.NONE));
                            alert(`Sorry, ${processingFiles.join(', ')} still chunking. It can take up to 5 min per each file`)
                        }
                        console.log('processingFiles', processingFiles)
                    } else {
                        alert("Sorry, something went wrong.")
                    }
                }
            };
            request.open("POST", `${BASE_URL}/api/file/check`, true);
            request.send(formData);
        } else {
            throw new Error("Failed to process files");
        }
    }
);

export const processFiles = createAsyncThunk<void, undefined, { state: RootState }>(
    'files/processFiles',
    async (_payload, {getState, dispatch}) => {
        const state = getState();
        let files = state.files.files;
        let keywords = state.files.keywords;
        if (files && files.length && keywords) {
            dispatch(setKeywordsProcessed(keywords))
            dispatch(updateRequestStatus(RequestStatus.PENDING));
            const formData = new FormData()
            formData.set("keywords", keywords)
            formData.set("file0", files[0] || new File([], 'placeholder'))
            formData.set("file1", files[1] || new File([], 'placeholder'))
            formData.set("file2", files[2] || new File([], 'placeholder'))
            formData.set("file3", files[3] || new File([], 'placeholder'))
            formData.set("file4", files[4] || new File([], 'placeholder'))
            formData.set("file5", files[5] || new File([], 'placeholder'))
            const request = new XMLHttpRequest();
            request.withCredentials = true;
            files.forEach((file, index) => {
                formData.set(`file${index}`, file);
            });
            request.onreadystatechange = () => {
                if (request.readyState === 4) {
                    if (request.status == 200 || request.status == 201) {
                        dispatch(updateRequestStatus(RequestStatus.FULFILLED));
                        dispatch(setFilePath(request.response));
                    } else {
                        alert("Sorry, something went wrong.")
                    }
                }
            };
            request.open("POST", `${BASE_URL}/api/file/process`, true);
            request.send(formData);
        } else {
            throw new Error("Failed to process files");
        }
    }
);

export const downloadFile = createAsyncThunk<void, undefined, { state: RootState }>(
    'files/downloadFile',
    async (_payload, {getState, dispatch}) => {
        const state = getState();
        const keywords = state.files.keywordsProcessed
        let filePath = state.files.filePath.replaceAll("\"", "");
        if (filePath) {
            fetch(`${BASE_URL}/api/file/download?filepath=${filePath}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf'
                },
                credentials: "include",
            })
                .then((response) => response.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `${keywords}.docx`,
                    );
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode?.removeChild(link);
                });
        } else {
            throw new Error("Failed to download file. No file URL");
        }
    }
);

export const filesSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        addFiles: (state, {payload}: PayloadAction<File[]>) => {
            if (!state.files) {
                if (payload.length <= 6) {
                    state.files = payload;
                } else {
                    alert("Sorry, you can't add more then 6 files")
                }
            } else {
                const allFiles = [...state.files, ...payload];
                if (allFiles.length <= 6) {
                    state.files = allFiles;
                } else {
                    alert("Sorry, you can't add more then 6 files")
                }
            }
            state.filePath = '';
            state.requestStatus = RequestStatus.NONE;
            state.downloadRequestStatus = RequestStatus.NONE;
        },
        deleteFile: (state, {payload}: PayloadAction<number>) => {
            if (state.files) {
                state.files = state.files.filter((file, index) => index != payload);
            }
            state.filePath = '';
            state.requestStatus = RequestStatus.NONE;
            state.downloadRequestStatus = RequestStatus.NONE;
        },
        updateKeywords: (state, {payload}: PayloadAction<string>) => {
            state.keywords = payload
        },
        updateRequestStatus: (state, {payload}: PayloadAction<RequestStatus>) => {
            state.requestStatus = payload
        },
        setKeywordsProcessed: (state, {payload}: PayloadAction<string>) => {
            state.keywordsProcessed = payload
        },
        setFilePath: (state, {payload}: PayloadAction<string>) => {
            state.filePath = payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(downloadFile.pending, (state) => {
                state.downloadRequestStatus = RequestStatus.PENDING;
            })
            .addCase(downloadFile.fulfilled, (state, action) => {
                state.downloadRequestStatus = RequestStatus.FULFILLED;
            })
            .addCase(downloadFile.rejected, (state) => {
                state.downloadRequestStatus = RequestStatus.NONE;
            });
    },
});

export const {
    addFiles,
    deleteFile,
    updateKeywords,
    updateRequestStatus,
    setKeywordsProcessed,
    setFilePath
} = filesSlice.actions;

export const filesSelector = (state: RootState): File[] => state.files.files || [];
export const filesSelectedSelector = (state: RootState): boolean => !!state.files.files;
export const keywordsSelector = (state: RootState): string => state.files.keywords;
export const requestStatusSelector = (state: RootState): RequestStatus => state.files.requestStatus;

export default filesSlice.reducer;
