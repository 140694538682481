import React, {ChangeEvent} from "react";
import styles from "./Content.module.css";
import {UploadComponent} from "../UploadComponent/UploadComponent";

interface ContentProps {
    handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export const Content: React.FC<ContentProps> = ({handleFileChange}) => {

    return <div className={styles.content}>
        <UploadComponent handleFileChange={handleFileChange}/>
    </div>
}
