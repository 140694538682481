import React from "react";
import styles from "./Keywords.module.css";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    checkFiles,
    keywordsSelector,
    RequestStatus,
    requestStatusSelector,
    updateKeywords
} from "../../fileSlice";

export const Keywords: React.FC = () => {

    const dispatch = useAppDispatch();
    const keywords = useAppSelector(keywordsSelector);
    const requestStatus = useAppSelector(requestStatusSelector);

    const handleClick = () => {
        dispatch(checkFiles());
    }

    function getButtonTitle() {
        switch (requestStatus) {
            case RequestStatus.FULFILLED:
                return "Analyze again";
            case RequestStatus.PENDING:
                return "Analyzing... This might take a few minutes to run";
            case RequestStatus.NONE:
                return "Analyze report";
        }
    }

    return <div className={styles.keywordsWrapper}>
        <span className={styles.title}>Enter keyword</span>
        <span className={styles.subTitle}>Write a single word or several keywords separated by ; for analysis (e.g. window; door )</span>
        <input
            value={keywords}
            placeholder={'Keyword'}
            className={styles.input}
            onChange={e => dispatch(updateKeywords(e.target.value))}
        />
        <div className={(requestStatus == RequestStatus.FULFILLED || !keywords) ? styles.uploadButtonEmpty : styles.uploadButton}
             onClick={handleClick}>
            {requestStatus == RequestStatus.PENDING && <div className="loader"/>}
            {getButtonTitle()}
        </div>
    </div>
}
