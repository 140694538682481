import React from "react";
import styles from "./SelectedFiles.module.css";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {deleteFile, filesSelector} from "../../fileSlice";

interface SelectedFilesProps {
    onUploadButtonClick: () => void
}

export const SelectedFiles: React.FC<SelectedFilesProps> = ({onUploadButtonClick}) => {

    const dispatch = useAppDispatch();
    const files = useAppSelector(filesSelector);

    const handleDeleteFile = (index: number) => {
        dispatch(deleteFile(index));
    }

    return <div className={styles.filesWrapper}>
        {files.map((file, index) => {
            return <div className={styles.rowWrapper}>
                <span className={styles.rowNumber}>{index + 1}.</span>
                <img className={styles.documentIcon} src={'./documentIcon.svg'} alt={'file'}/>
                <span className={styles.fileName}>{file.name}</span>
                <img
                    className={styles.deleteIcon}
                    src={'./closeIcon.svg'}
                    alt={'delete'}
                    onClick={e => handleDeleteFile(index)}
                />
            </div>
        })}
        <div className={styles.uploadButton} onClick={onUploadButtonClick}>
            Upload PDF
        </div>
    </div>
}
