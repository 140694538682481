import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {FullPage} from "../FullPage/FullPage";
import {SignedIn, SignedOut} from "@clerk/clerk-react";
import {Login} from "../Login/Login";

export const App: React.FC = () => {
    return <BrowserRouter>
        <Switch>
            <Route
                path="/sign-in/*" component={Login}
            />
            <Route path="/" component={() =>
                <>
                    <SignedIn>
                        <FullPage/>
                    </SignedIn>
                    <SignedOut>
                        <Login/>
                    </SignedOut>
                </>
            }/>

        </Switch>
    </BrowserRouter>
}
